import Loader from 'components/Loader';
import PropTypes from 'prop-types';

const AppLoading = ({ text }: any) => <Loader animated text={text} />;

AppLoading.defaultProps = {
  text: 'Cargando Datos'
};

AppLoading.propTypes = {
  text: PropTypes.string
};

export default AppLoading;
