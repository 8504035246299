import { Suspense, useEffect } from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import AppShellComponent from 'components/AppLoading';
import App from 'components/App';
import { useSearchParam } from 'react-use';
import {
  AsyncHomeNewLpPage,
  AsyncAvaluos,
  Campaigns,
  HomeLqn,
  VirtualRoutes,
  Terms,
  Policies,
  AsyncBDBPerfilador,
  ColpatriaViabilidad,
  AsyncMexico,
  ConsultaCredito,
  VerifyOTP,
  MyCreditApplications,
  ClientsPage,
  UploadApprovalLetter
} from 'pages';
import { GAnalytics } from 'services/GAnalytics';
import { facebookPixel } from 'services/ConfigFacebookPixel';
import SimpleRoute from './components/SimpleRoute';

const Routes = props => {
  const { childProps, location } = props;

  useEffect(() => {
    GAnalytics.track(location.pathname);
    facebookPixel.trackingPageView();
  }, [location.pathname]);

  return (
    <App>
      <Suspense fallback={<AppShellComponent />}>
        <Switch>
          <SimpleRoute exact path="/" component={HomeLqn} props={childProps} />
          <SimpleRoute exact path="/home" component={HomeLqn} props={childProps} />
          <SimpleRoute exact path="/homeLp" component={AsyncHomeNewLpPage} props={childProps} />
          <SimpleRoute path="/terminos" component={Terms} props={childProps} />
          <SimpleRoute path="/politicas" component={Policies} props={childProps} />
          <SimpleRoute path="/oficina-virtual" component={VirtualRoutes} props={childProps} />
          <SimpleRoute path="/c" component={Campaigns} props={childProps} />
          <SimpleRoute path="/m" component={AsyncMexico} props={childProps} />
          <SimpleRoute path="/clients" component={ClientsPage} props={childProps} />
          <SimpleRoute path="/avaluos" component={AsyncAvaluos} props={childProps} />
          <SimpleRoute path="/bdb-link" component={AsyncBDBPerfilador} props={childProps} />
          <SimpleRoute path="/colpatria-viabilidad" component={ColpatriaViabilidad} props={childProps} />
          <SimpleRoute path="/consulta-credito" component={ConsultaCredito} props={childProps} />
          <SimpleRoute path="/verify-otp" component={VerifyOTP} props={childProps} />
          <SimpleRoute path="/mis-solicitudes" component={MyCreditApplications} props={childProps} />
          <SimpleRoute path="/carta-aprobacion" component={UploadApprovalLetter} props={childProps} />

          <SimpleRoute
            exact
            path="/bcs"
            props={childProps}
            component={() => {
              return <Redirect to="/?utm_source=feriacs&utm_medium=call&utm_campaign=feriacajasocial" />;
            }}
          />
          <SimpleRoute
            exact
            path="/store"
            component={() => {
              const code = useSearchParam('referrerCode', '');
              return <Redirect to={`/oficina-virtual?code=${code}`} />;
            }}
          />
          <SimpleRoute
            exact
            path="/property/:code"
            props={childProps}
            component={({
              match: {
                params: { code }
              }
            }) => {
              return <Redirect to={`/DetailProperty?codeProperty=${code}`} />;
            }}
          />

          <Redirect to="/" />
        </Switch>
      </Suspense>
    </App>
  );
};

export default withRouter(Routes);
