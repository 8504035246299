/* eslint-disable camelcase */
import { lazy } from 'react';

export const HomeLqn = lazy(() => import('./Home'));

export const AsyncHomeNewLpPage = lazy(() => import('./HomeNewLp'));

export const AsyncAvaluos = lazy(() => import('./Avaluos'));

export const LandingHome = lazy(() => import('./Campaigns/LandingPageHome'));

export const Campaigns = lazy(() => import('./Campaigns'));

export const Cxc = lazy(() => import('./PortfolioPurchase'));

export const VirtualOffice = lazy(() => import('./VirtualOffice'));

export const Terms = lazy(() => import('./Terms'));

export const Policies = lazy(() => import('./Policies'));

export const AsyncBDBPerfilador = lazy(() => import('./BDBPefilador'));

export const ColpatriaViabilidad = lazy(() => import('./ColpatriaViabilidad'));

export const VirtualRoutes = lazy(() => import('./VirtualRoutes'));

export const AsyncMexico = lazy(() => import('./Mexico'));

export const ConsultaCredito = lazy(() => import('./ConsultaCredito'));

export const VerifyOTP = lazy(() => import('./VerifyOTP'));

export const MyCreditApplications = lazy(() => import('./MyCreditApplications'));

export const ClientsPage = lazy(() => import('./Clients'));

export const UploadApprovalLetter = lazy(() => import('./UploadApprovalLetter'));
