import ReactGA from 'react-ga';

ReactGA.initialize(process.env.REACT_APP_GA);

const actions = {
  track: page => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }
};

export const GAnalytics = actions;
