import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import StorageManager from 'services/Storage';

const authLink = setContext((_, { headers }) => {
  const token = StorageManager.get(process.env.REACT_APP_TOKEN_SLOT);
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : ''
    }
  };
});

const link = createUploadLink({
  uri: process.env.REACT_APP_ENDPOINT
});
export const apolloCache = new InMemoryCache();
export const client = new ApolloClient({ link: authLink.concat(link), cache: apolloCache });
