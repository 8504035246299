/* eslint-disable global-require */
import L from 'react-loaders';
import Lottie from 'react-lottie';
import './index.scss';

interface ILoader {
  type?: any;
  text: string;
  animated: boolean;
}

const Loader = ({ type, text, animated }: ILoader) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('./animations/animation-w400-h400.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  // const color: string = '#CE1277';
  return (
    <div className="loader-box">
      <div>
        {animated ? <Lottie options={defaultOptions} height={150} width={150} /> : <L active={!animated} type={type} />}
        {text && <strong>{text}</strong>}
      </div>
    </div>
  );
};

Loader.defaultProps = {
  type: 'line-scale-pulse-out'
};

export default Loader;
