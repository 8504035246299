import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
};

ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);

const actions = {
  trackingPageView: () => {
    ReactPixel.pageView(); // For tracking page view
  }
  // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
  // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
  // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
  // ReactPixel.trackSingleCustom('PixelId', event, data);
};

export const facebookPixel = actions;
