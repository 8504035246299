import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'url-search-params-polyfill';
import { QueryParamProvider } from 'use-query-params';
import './App.scss';
import 'antd/dist/antd.css';

import Routes from './Routes';

if (process.env.NODE_ENV === 'production') {
  const { host } = window.location;
  const { protocol } = window.location;
  const parts = host.split('.');
  const subdomain = parts[0];
  if (parts.length >= 3 && subdomain !== 'dev') {
    // eslint-disable-next-line prefer-destructuring
    parts.splice(0, 1);
    window.location = `${protocol}//${parts.join('.')}${window.location.pathname}${window.location.search}`;
  }
}

const App = () => (
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Routes />
    </QueryParamProvider>
  </Router>
);

function MainApp() {
  return <App />;
}

export default MainApp;
