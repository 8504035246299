/* eslint-disable react/jsx-filename-extension */
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderNew } from '@apollo/client';
import { client as clientNew } from 'services/apollo';
import client from 'services/ApolloSetup';
import { ConfigProvider } from 'antd';
import { CookiesProvider } from 'react-cookie';
import esES from 'antd/lib/locale-provider/es_ES';
import { hotjar } from 'react-hotjar';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);

const WrappedApp = (
  <ApolloProvider client={client}>
    <ConfigProvider locale={esES}>
      <CookiesProvider>
        <ApolloProviderNew client={clientNew}>
          <App />
        </ApolloProviderNew>
      </CookiesProvider>
    </ConfigProvider>
  </ApolloProvider>
);
ReactDOM.render(WrappedApp, document.getElementById('root'));

registerServiceWorker();
